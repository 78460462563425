import { Store } from 'vuex';
import * as mutations from './mutations';
import * as getters from './getters';
import * as actions from './actions';

const createStore = (context) => {
    const state = context?.state;

    return new Store({
        state() {
            return {
                cart: null,
                toggleCart: false,
                toggleLanguageSelector: false,
                openSections:[],
                cookies: null,
                post: {},
                posts: [],
                author: {},
                featuredPost: {},
                featured2Posts: [],
                productPosts: [],
                maternityPosts: [],
                exampleListsPosts: [],
                categoryPosts: [],
                tag: [],
                step: null,
                retailers: {
                    0: 'HelloBB',
                    1: 'Bebitus',
                    2: 'Amazon',
                    5: 'El Corte Inglés',
                    6: 'Ikea',
                    243: 'HelloBB',
                    61: 'BabyBjörn',
                    22: 'Vertbaudet',
                    117: 'Carrefour',
                    16: 'Maisonsdumonde',
                    29: 'Stokke',
                    51: 'Tutete' ,
                    53: 'BBlandia',
                    69: 'Planeta Huerto',
                    9: 'Infantdeco',
                    276: "Oh My Baby"
                },
                steps: [
                    {
                        number: 1,
                        image: 'https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5f71b570e647295b0da126b8_stork.png',
                        dataWId: "7911b6ae-8b65-3048-2074-ab3aac8fc3d2", // TODO CAL? on és weblow només o són indicadors per automatitzar proves?
                        name: '&iquest;Cu&aacute;ndo nacer&aacute;?',
                        checked: false
                    },
                    {
                        number: 2,
                        image: 'https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5f71b6d84742001ec5b179af_baby%20(1).png',
                        dataWId: "0f24e36c-866f-2ca3-4dcc-ee4b740a7f04",
                        name: '&iquest;Sabes su nombre?',
                        checked: false
                    },
                    {
                        number: 3,
                        image: 'https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5f71b6d7d5662d3f76f87f9c_speech-bubble.png',
                        dataWId: "f1346b83-fd41-5dfc-0669-eae1efd58756",
                        name: 'A&ntilde;ade un mensaje de bienvenida',
                        checked: false
                    },
                    {
                        number: 4,
                        image: 'https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5f71b6d804659a3d94e9c190_camera.png',
                        dataWId: "ca66f382-82c9-cafb-949e-d94e0f7d241d",
                        name: 'A&ntilde;ade tu foto',
                        checked: false
                    }
                ],
                isAuthenticated: false,
                auth_token: '',
                user: null,
                searchState: false,
                addToListVisibility: false,
                ctaFixedVisibility: true,
                ctaFixedVisibility2: true,
                users: '',
                orderBy:'relevance',
                categories: [],
                categoriesPrefetched: false,
                collections: [],
                collectionsPrefetched: false,
                myList: {},
                listUpdated: true,
                ownerList: {},
                ownerListProduct: {},
                products: [],
                productsConstructor: [],
                loadingProducts: false,
                productsPrefetched: false,
                product: {},
                selectedOptions: [],
                selectedQuantity: 1,
                productFilters: [], // Examples [{name: 'price',values: {minPrice: 1, maxPrice: 2}}]
                guestProductFilters: [], // Examples [{name: 'state',value: 'Reservado'}]
                ownerProductFilters: [],
                waitingFilters: true,
                savedProducts: [],
                selectedCategory: {
                    id: 0,
                    name: '',
                    level: 0,
                    subCategories: [],
                    mainCategory: '',
                    subCategory: '',
                    mainCategoryId: 0
                },
                brands:[],
                tags:[],
                showAlert: false,
                showAlertKey: null,
                alertMessage: '',
                alertType: 'sucess',
                showRetry: false,
                alertRetrying: false,
                parserError: false,
                parserErrorUrl: '',
                invitation: {},
                listConstructor: {},
                limit: null,
                temporalLimit: 0,
                toggleCategory: false,
                collection: null,
                collectionProducts: null,
                collectionProductsPrefetched: false,
                breadcrumb: [],
                firstStepData: {
                    cuentaBancaria: '',
                    moneda: '',
                    nombre: '',
                    apellidos: '',
                    fechaNacimiento: '',
                    telefono: '',
                },
                secondStepData: {
                    direccion: '',
                    codigoPostal: '',
                    ciudad: '',
                    provincia: '',
                    email: '',
                },
                confirmationMessage: {
                    message: '',
                    showMessage: false,
                    showMessageError: false
                },
                listStepPwd: "",
                landbotVisible: true,
                contributionsFlag: false,
                reloadLandbot: false,
                constructorQuery: '',
                currencies: [],
                externalAccount: null,
                listActive: false,
                isButtonUser: false,
                guestCountry: context?.country || null,
                browserLanguage: "es",
                promotions: [],
                nextPromotionIndex: 0,
                loginNoName: true,
            }
        },
        mutations,
        getters,
        actions,
    });
};

export default createStore;
