import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { setStripeData } from "./mutations";
import { getClientIdCustomizations } from "../integrations/external-ecommerce/api";
const baseURL = process.env.API;

let ghostApi;
import("@tryghost/content-api").then(module => {
    ghostApi = new module.default({
        url: process.env.BLOG_URL,
        key: process.env.BLOG_API_KEY,
        version: process.env.BLOG_API_VERSION,
    });
})


export const getTag = async ({ commit }, slug) => {
    try {
        const response = await ghostApi.tags.read({
            slug: slug
        });
        commit("setTag", response);
    } catch (error) {
        console.log(error);
    }
}

export const getPost = async ({ commit }, slug) => {
    try {
        const response = await ghostApi.posts.read({
            slug: slug,
            include: "tags,authors",
        });
        commit("setPost", response);
        return response;
    } catch (error) {
        console.error("Error en getPost action", error);
        throw error;
    }
}

export const getFeaturedPost = async ({ commit }) => {
    try {
        const response  = await ghostApi.posts.browse({
            filter: "featured:true",
            limit: 1,
            include: "tags",
            order: "created_at DESC"
        });
        if (response && response[0]) {
            commit("setFeaturedPost", response[0]);
        }
    } catch (error) {
        console.log(error);
    }
}

export const getFeatured2Posts = async ({ commit }) => {
    try {
        const response  = await ghostApi.posts.browse({
            filter: "tag:destacado",
            limit: 6,
            include: "tags",
            order: "created_at DESC"
        });
        commit("setFeatured2Posts", response);

    } catch (error) {
        console.log(error);
    }
}

export const getProductPosts = async ({ commit }) => {
    try {
        const response  = await ghostApi.posts.browse({
            filter: "tag:productos",
            limit: 6,
            include: "tags",
            order: "created_at DESC"
        });
        commit("setProductPosts", response);

    } catch (error) {
        console.log(error);
    }
}

export const getMaternityPosts = async ({ commit }) => {
    try {
        const response  = await ghostApi.posts.browse({
            filter: "tag:maternidad",
            limit: 6,
            include: "tags",
            order: "created_at DESC"
        });
        commit("setMaternityPosts", response);
    } catch (error) {
        console.log(error);
    }
}

export const getExampleListsPosts = async ({ commit }) => {
    try {
        const response  = await ghostApi.posts.browse({
            filter: "tag:listas-de-ejemplo",
            limit: 6,
            include: "tags",
            order: "created_at DESC"
        });
        commit("setExampleListsPosts", response);

    } catch (error) {
        console.log(error);
    }
}

export const getCategoryPosts = async ({ commit, state }) =>{
    try {
        const response = await ghostApi.posts.browse({
            filter: "tag:" + state.route.params.slug,
            limit: 'all',
            include: "tags",
            order: "created_at DESC"
        });
        commit("setCategoryPosts", response);
    } catch (error) {
        console.log(error);
    }
}


export const getPosts = async ({ commit }) =>{
    try {
        const response = await ghostApi.posts.browse({
            limit: 'all',
            include: "tags",
            order: "created_at DESC"
        });
        commit("setPosts", response);
    } catch (error) {
        console.log(error);
    }
}


//todo comprobar porque recupera menos tags según la petición
//PETICION A TRAVES DEL GHOTSAPI
// export const getBlogTagsData = async ({ commit, state }, server = false) => {
//     try {
//         const response = await ghostApi.tags.browse();
//
//         const blogTags = response.map((tag) => ({
//             id: tag.id,
//             name: tag.name,
//             slug: tag.slug,
//         }));
//         console.log(blogTags);
//
//         commit("setBlogTags", blogTags);
//         if (server) commit("setBlogTagsPrefetched", server);
//     } catch (error) {
//         console.error("Error al obtener las categorías del blog:", error);
//     }
// };


//PETICION DIRECTA A LA API
export const getBlogTagsData = async ({ commit, state }, server = false) => {
    try {
        const ghostApiUrl = "https://hellobb.ghost.io/ghost/api/v3/content/tags/?key=0a666181ef624b5781de4486c8&page=0&limit=100";
        const response = await axios.get(ghostApiUrl);

        const blogTags = response.data.tags.map((tag) => ({
            id: tag.id,
            name: tag.name,
            slug: tag.slug,
        }));

        commit("setBlogTags", blogTags);
        if (server) commit("setBlogTagsPrefetched", server);
    } catch (error) {
        console.error("Error al obtener las categorías del blog:", error);
    }
};



export const setLimitSectionsToShow = async ({ commit, state }) => {
    const result = await axios.get(baseURL + "/user/sectionstoshow",axiosOptions(state));
    if (result?.data) {
        commit("setLimit", result.data);
    }
}

export const setLimitSectionsToShow2 = async ({ state }) => {
    const result = await axios.put(baseURL + "/user/incrementsectionstoshow", {}, axiosOptions(state));
}

// eslint-disable-next-line no-unused-vars
export const addProductToList = async ({ state },{ list, product, variantId, body = {} }) => {
    console.log(list)
    return await addProductToListByProductId({ state, list, productId: product.id, variantId, body: body});
}
// eslint-disable-next-line no-unused-vars
const addProductToListByProductId = async ({ state, list, productId, variantId, body = {} }) => {
    if (list.id === undefined) list.id = 0;
    if (!variantId || variantId == 0) return await axios.put( baseURL + `/list/${list.id}/product/${productId}`, body , axiosOptions(state));
    else return await axios.put( baseURL + `/list/${list.id}/product/${productId}/variant/${variantId}`, body , axiosOptions(state));
}

export const getLoggedUserList = async (state) => {
    return await axios.get( baseURL + '/list', axiosOptions(state));
}

export const setList = async ({ commit, state }) => {
    const response = await getLoggedUserList(state)
    commit("setMyList", response);
}

export const setUserList = async ({ commit, state }) => {
    const {data: list} = await getLoggedUserList(state);
    commit("setUserList", list);
}


export const setMyList = async ({ commit, state }, list) => {
    commit("setMyList", list);
}
// eslint-disable-next-line no-unused-vars
export const checkEmail = async ({ state }, { email, product }) => {
    return await axios.get(baseURL + "/list/reservation/" + email + "/listproduct/" + product.listProductId );
}

// eslint-disable-next-line no-unused-vars
export const checkEmailFreeProduct = async ({ state }, { email, product }) => {
    return await axios.get(baseURL + "/list/reservation/" + email + "/product/" + (product.isFree ? "free/" : "") + product.id);
}

export const cancelBooking = async ({ state }, { product, email, id }) => {
    await axios.delete(baseURL + "/list/reservation/" + (product.isFree ? "free/" : "") + email + "/" + id , axiosOptions(state));
}

export const sendEnCaminoEmail = async ({ state }, { list, productId, body }) => {
    await axios.post(baseURL + "/list/" + list.id + "/product/" + productId + "/purchase", JSON.stringify(body) , axiosOptions(state));
}

//possibily deprecated since multi-list context was added
export const updateProductComment = async ({ state }, { product, body }) => {
    await axios.put(baseURL + "/list/listproduct/" + product.listProductId + "/comment", JSON.stringify(body) ,axiosOptions(state));
}

export const updateListProductComment = async ({ state }, { listId, product, body }) => {
    await axios.put(baseURL + "/list/" + listId + "/listproduct/" + product.listProductId + "/comment", JSON.stringify(body) ,axiosOptions(state));
}

export const updateProductVariant = async ({ state }, { listProductId, variantId, body }) => {
    await axios.put(baseURL + "/list/listproduct/" + listProductId, JSON.stringify(body) ,axiosOptions(state));
}

export const updateFreeProductData = async ({ state }, { listId, product, body }) => {
    console.log({ listId, product, body });

    if (listId === undefined) listId = 0;
    await axios.put(baseURL + "/list/" + listId + "/product/free/" + product.id, JSON.stringify(body) ,axiosOptions(state));
}

export const updateProductStatus = async ({ state }, { product , status}) => {
    // possibily deprecated since multi-list context was added
    await axios.put(baseURL + "/list/listproduct/" + product.listProductId + "/status/" + status,null,axiosOptions(state));
}

export const updateListProductStatus = async ({ state }, { list, product , status}) => {
    await axios.put(baseURL + "/list/" + list.id + "/listproduct/" + product.listProductId + "/status/" + status,null,axiosOptions(state));
}

export const updateFreeProductStatus = async ({ state }, { list, product , status}) => {
    await axios.put(baseURL + "/list/" +  list.id + "/product/" + product.id + "/status/" + status,null,axiosOptions(state));
}

// eslint-disable-next-line no-unused-vars
export const updateProductStatusForGuest = async ({ commit, state }, { list, product , status}) => {
    if(!product.isFree) await axios.put(baseURL + "/list/" + list.id + "/listproduct/" + product.listProductId + "/status/" + status + "/guest",null);
    else await axios.put(baseURL + "/list/" + list.id + "/product/" + product.id + "/status/" + status + "/guest",null);
    //set product as bought
    console.log("updateProductStatusForGuest")
    commit("setProductAsBought", product);
}

// NOTA: només per als productes free
export const removeProductFromList = async({ state }, {listId, product}) => {
    await axios.delete(baseURL + "/list/" + listId + "/product/" +  (product.isFree ? "free/" : "") + product.id,axiosOptions(state));
}

export const resetPassword = async ({ state }, passwordChangeRequest) => {
    return await axios.put(baseURL + "/user/password", passwordChangeRequest, axiosOptions(state));
}

const updateSessionUserField = async(state,key,value) => {
    await axios.patch( "/user", {
        key,
        value
    },axiosOptions(state));
}

export const updateUser = async({ commit , state }, user) => {
    commit("updateUser", user);
    await axios.put(baseURL + "/user", JSON.stringify(user), axiosOptions(state));
    await updateSessionUserField(state, "name",user.name)
    await updateSessionUserField(state, "phone",user.phone)
    await updateSessionUserField(state, "paypal",user.paypal)
    await updateSessionUserField(state, "adress",user.adress)
}

export const updateUserRoleInSession = async({ commit, state }, role) => {
    // TODO: We might want to ensure that `role` has a valid value.

    commit("updateUserField", {
        field: "userType",
        value: role,
    });

    await updateSessionUserField(state, "userType", role)
}

export const updateChildBirthday = async({ commit, state },childBirthday) => {
    await axios.patch(baseURL + "/user/childbirthday", JSON.stringify(childBirthday) ,axiosOptions(state));
    //commit("updateUserField", { field: 'childBirthday', value: childBirthday })
    await updateSessionUserField(state, "childBirthday",childBirthday)
    commit("updateListField", { field: "childBirthday", value: childBirthday })
}

export const updateChildName = async({ commit, state },childName) => {
    await axios.patch(baseURL + "/user/childname", JSON.stringify(childName) ,axiosOptions(state));
    await updateSessionUserField(state, "childName", childName)
    commit("updateListField", { field: "childName", value: childName })
}

export const updateWelcomeMessage = async({ commit, state }, welcomeMessage) => {
    await axios.patch(baseURL + "/user/welcomemessage", JSON.stringify(welcomeMessage) ,axiosOptions(state));
    commit("updateUserField", { field: "welcomeMessage", value: welcomeMessage })
    commit("updateListField", { field: "welcomeMessage", value: welcomeMessage })
    await updateSessionUserField(state, "welcomeMessage",welcomeMessage)
}

export const updateName = async({ commit, state }, name) => {
    await axios.patch(baseURL + "/user/name", JSON.stringify(name) ,axiosOptions(state));
    commit("updateUserField", { field: "name", value: name })
    commit("updateListField", { field: "ownerName", value: name })
    await updateSessionUserField(state, "name",name)
}

export const sendInvitation = async ({ commit, state },  collaboratorData ) => {
    try {
        const response = await axios.post(baseURL + "/user/collaborator/invitation", JSON.stringify(collaboratorData), axiosOptions(state));

        // Verifica si el servidor devolvió un código de estado 200
        if (response.status === 200) {
            await updateSessionUserField(state, "invitationText", collaboratorData.name + " (" + collaboratorData.email + ")")
            await updateSessionUserField(state, "collaboratorRefused", false)


            const invitationData = {
                invitationText: collaboratorData.name + " (" + collaboratorData.email + ")",
                invitationStatus: "pending"
            };

            commit("setInvitationData", invitationData);
            commit("notRefuseCollaborator");

            return response;
        } else {
            //throw new Error(`Error en la respuesta del servidor: ${response.status}`);
            return response;
        }
    } catch (error) {
        console.error("Error al crear el colaborador:", error);
        throw error;
    }
};

export const createCollaborator = async ({ commit, state }, collaboratorData, user) => {

    try {
        const response = await axios.post(baseURL + "/user/collaborator", JSON.stringify(collaboratorData), axiosOptions(state));
        // Verifica si el servidor devolvió un código de estado 200
        if (response.status === 200) {
            commit("setPartner", {
                name: collaboratorData.name,
                email: collaboratorData.email,
                id: response.data,
            });
            return response;
        } else {
            // Si el servidor devuelve un código de estado diferente, puedes manejarlo según tus necesidades
            throw new Error(`Error en la respuesta del servidor: ${response.status}`);
        }
    } catch (error) {
        // Manejar errores de red u otros errores de Axios
        console.error("Error al crear el colaborador:", response.data);
        throw error; // Puedes propagar el error si es necesario
    }
};



export const refuseCollaborator = async ({ commit, state }, collaboratorData) => {
    let res = await axios.put(baseURL + "/user/collaborator/refuse", {}, axiosOptions(state));

    if (res.status === 200) {
        await updateSessionUserField(state, "collaboratorRefused", true)
        commit("refuseCollaborator");
    }
    return res;
};

export const refuseContributions = async ({ commit, state }, collaboratorData) => {
    let res = await axios.put(baseURL + "/user/contribution/refuse", {}, axiosOptions(state));

    if (res.status === 200) {
        await updateSessionUserField(state, "refuseContributions", true)

        commit("refuseContributions");
    }
    return res;
};

export const deleteCollaborator = async ({ commit, state }, collaboratorData) => {
    let res = await axios.delete(baseURL + "/user/collaborator", axiosOptions(state));
    await updateSessionUserField(state, "partner", null)

    if (res.status === 200) {
        await updateSessionUserField(state, "collaboratorRefused", false)
        commit("notRefuseCollaborator");

        commit("deleteCollaborator");
    }
    return res;
};

export const deleteInvitation = async ({ commit, state }, collaboratorData) => {
    let res = await axios.delete(baseURL + "/user/collaborator/invitation", axiosOptions(state));
    await updateSessionUserField(state, "invitationText", null)

    if (res.status === 200) {
        commit("deleteInvitation");
    }
    return res;
};

export const setUserImage = async({ commit, state }, image) => {
    const response = await axios.post(baseURL + "/user", JSON.stringify(image), axiosOptions(state));
    commit("setUserPhoto", response.data?.photo);
    commit("setOwnerListPhoto", response.data.photo);
    await updateSessionUserField(state, "photo",response.data?.photo)
}

// eslint-disable-next-line no-unused-vars
export const recoverPwd = async({ state }, email) => {
    return await axios.get(baseURL + "/user/recoverpwd?email=" + email);
}

export const resetPasswordPwd = async({ state }, email) => {
    return await axios.get(baseURL + "/user/resetpwd/" + email);
}

export const updateListTitle = async({ state, commit }, {list,title}) => {
    await axios.put(baseURL + "/list/" + list.id, JSON.stringify({ title: title}) ,axiosOptions(state));
    await updateSessionUserField(state, "listName",title)
    commit("updateListField", { field: 'title', value: title })
    commit("updateUserField", { field: 'listName', value: title })
}

export const bookFreeProduct = async({ state }, { list, product, email, name }) => {
    await axios.post(baseURL + "/list/" + list.id + "/product/free/" + product.id + "/reserve", JSON.stringify({ email, name }) ,axiosOptions(state));
}

export const bookProduct = async({ state }, { list, product, email, name }) => {
    await axios.post(baseURL + "/list/" + list.id + "/listproduct/" + product.listProductId + "/reserve", JSON.stringify({ email, name }) ,axiosOptions(state));
}

export const addProduct = async({ state }, { list, product } ) => {
    await axios.post(baseURL + "/list/" + list.id + "/product", JSON.stringify(product) ,axiosOptions(state));
}

export const updateFund = async({ state }, fund ) => {
    await axios.put(baseURL + "/fund/", JSON.stringify(fund) ,axiosOptions(state));
}

export const removeFund = async({ state }, fund ) => {
    await axios.delete(baseURL + "/fund/" + fund.id ,axiosOptions(state));
}

export const addFund = async({ state }, fund ) => {
    await axios.post(baseURL + "/fund", JSON.stringify(fund) ,axiosOptions(state));
}

export const contributeToFund = async({ state }, { fund, contribution } ) => {
    await axios.post(baseURL + "/fund/" + fund.id + "/fundcontribution", JSON.stringify(contribution) ,axiosOptions(state));
}

export const getCategories = ({ commit },server = false) => {
    return axios.get(baseURL + '/category').then(response => {
        commit("setCategories", response.data);
        if (server) commit("setCategoriesPrefetched", server);
    }).catch(err => {
        console.log(err);
    });
}

export const getCollections = ({ commit }, server = false) => {
    return axios.get(baseURL + '/collection').then(response => {
        commit("setCollections", response.data);
        if (server) commit("setCollectionsPrefetched", server);
    }).catch(err => {
        console.log(err);
    });
}




export const getList = ({ commit }, id) => {
    return axios.get(baseURL + '/list/' + id).then(response => {
        commit("setMyList", response.data);
    }).catch(error => {
        console.log(error);
        commit("setMyList", {
            funds: [],
            products: []
        });
    });
}

export const getListOnly = ({ }, id) => {
    return axios.get(baseURL + '/list/' + id).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);

        return [];
    });
}

export const removeListProduct = async ({ state }, { productId }) => {
    await axios.delete(baseURL + '/list/listproduct/' + productId,axiosOptions(state));
}

export const removeListProductByListId = async ({ state }, { listId, productId }) => {
    await axios.delete(baseURL + '/list/' + listId + '/listproduct/' + productId,axiosOptions(state));
}

// Aquesta dada la usarem per obtenir el producte de la llista. Haurem de canviar l'endpoint per fer el GET de producte.
// El nou és GET /list/listproduct/{listProductId}
export const getListProduct = async ({ commit , state }, { productId, server }) => {
    const response = await axios.get(baseURL + '/list/listproduct/' + productId,axiosOptions(state));
    let product = response.data
    if (server) product.server = server
    commit("setOwnerListProduct", product);

    return product;
}

export const getOwnerListFreeProduct = async ({ commit , state }, { listId,productId, server }) => {
    if (listId === undefined) listId = 0;
    const response = await axios.get(baseURL + '/list/' + listId + '/product/free/' + productId,axiosOptions(state))
    const product = response.data
    if (server) product.server = server
    commit("setOwnerListProduct", product);

    return product;
}

export const getConnectedUser = async ({ commit , state }) => {
    const response = await axios.get(baseURL + '/user/connected',axiosOptions(state));
    const connectedUser = response.data;

    if (typeof connectedUser.listActive === "boolean") {
        commit("setListActive", connectedUser.listActive);
    }
    if (typeof connectedUser.isButtonUser === "boolean") {
        commit("setIsButtonUser", connectedUser.isButtonUser);
    }

    commit("setCollaboratorDataOnServerSide", connectedUser);
    return connectedUser;
}

export const getUserApp = async ({ commit , state }) => {
    const response = await axios.get(baseURL + '/user/connected',axiosOptions(state));
    return response.data;
}

function axiosOptions(state) {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(state),
        },
    }
}

function getToken(state) {
    if (state.auth_token) return state.auth_token
    console.log('WARNING! TOKEN NO DISPONIBLE');
}

// TODO CALDRIA UNIFICAR getListTuCuenta i getOwnerList un cop tinguem tot el codi nou. Ara mateix hi ha dos states:
// 1) myList utilitzada a Catalogo i TuCuenta i altres llocs
// 2) OwnerList amb la llista nova de propietari
export const getListTuCuenta = ({ commit , state },server = false) => {
    return axios.get(baseURL + '/list',axiosOptions(state)).then(response => {
        const list = response.data
        list.server = server
        commit("setMyList", list);
    }).catch(error => {
        console.log(error);
        commit("setMyList", {
            server: server,
            funds: [],
            products: []
        });
    });
}

export const getOwnerList = ({ commit , state },server = false) => {
    return axios.get(baseURL + '/list',axiosOptions(state)).then(response => {
        const list = response.data
        list.server = server
        // OPTIMIZATION -> No calen tots els camps
        list.products = list.products.map(product => {
            return {
                id: product.id,
                listProductId: product.listProductId,
                name: product.name,
                brand: product.brand,
                url: product.url,
                status: product.status,
                mainCategory: product.mainCategory,
                mainCategoryId: product.mainCategoryId,
                mainCategorySlug: product.mainCategorySlug,
                subCategory1: product.subCategory1,
                photo: product.photo,
                rating: product.rating,
                price: product.price,
                minPrice: product.minPrice,
                maxPrice: product.maxPrice,
                mustHave: product.mustHave,
                isFree: product.isFree,
                productRetailers: product.productRetailers,
                retailerName: product.retailerName,
                shopifyId: product.shopifyId,
                shopifyProductId: product.shopifyProductId,
                shopifyProductVariantId: product.shopifyProductVariantId,
                selectedVariantText: product.selectedVariantText,
                contributionAmount: product.contributionAmount,
                contributionProgression: product.contributionProgression,
                logoUrl: product.logoUrl,
                // originalUrl: product.originalUrl
            }
        })
        commit("setOwnerList", list);
    }).catch(error => {
        console.log(error);
        commit("setOwnerList", {
            server: server,
            funds: [],
            products: []
        });
    });
}

export const setProductMustHave = async ({ state }, { product, musthave } ) => {
    await axios.patch(baseURL + '/list/listproduct/' + product.listProductId + "/musthave/" + musthave, null, axiosOptions(state) )
}

export const setFreeProductMustHave = async ({ state }, { list, product, musthave } ) => {
    await axios.patch(baseURL + '/list/' + list.id + "/product/" + product.id + "/" + musthave, null, axiosOptions(state) )
}

export const getListGuess = ({ commit }, id) => {
    return axios.get(baseURL + '/list/guess/' + id).then(response => {
        commit("setMyList", response.data);
    }).catch(err => {
        console.log(err);
    });
}

export const getListGuessByUser = ({ commit }, id) => {
    return axios.get(baseURL + '/list/guess/user/' + id).then(response => {
        commit("setMyList", response.data);
    }).catch(err => {
        console.log(err);
    });
}

export const authorizeGuest = async ({ commit }, authRequest) => {
    try {
        const response = await axios.post(baseURL + '/list/guest/authorize', JSON.stringify(authRequest), {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response;
    } catch (error) {
        console.error("Error al autorizar al invitado:", error);
        throw error;
    }
}

export const getListGuest = async ({ commit, state }, { id, server, auth}) => {
    try {
        var bodyAuth = false;
        if (auth) bodyAuth = true;
        if (state.isAuthenticated) bodyAuth = true;
        const response = await axios.post(
            baseURL + '/list/guest/' + id, 
            JSON.stringify(bodyAuth),
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        if (response.status === 404) {
            commit("setMyList", null);

            return;
        }

        let list = response.data
        if (server) list.server = server
        commit("setMyList", list);
    } catch (err) {
        const httpStatus = err?.response?.status;
        if (httpStatus === 404) {
            commit("setMyList", null);

            return;
        }

        console.error(err);
        throw err;
    }
}


export const getListGuestByUser = ({ commit }, id) => {
    return axios.get(baseURL + '/list/guest/user/' + id).then(response => {
        commit("setMyList", response.data);
    }).catch(err => {
        console.log(err);
    });
}

export const getProduct = ({ commit }, id) => {
    startNgProgress()
    return axios.get(baseURL + '/product/' + id).then(response => {
        commit("setProduct", response.data);
        commit('setCanScroll')
        NProgress.done();
    }).catch(err => {
        console.log(err);
        NProgress.done();
        throw err;
    });
}

export const getCollection = ({ commit,state }, id) => {
    return axios.get(baseURL + '/collection/' + id, axiosOptions(state)).then(response => {
        commit("setCollection", response.data);
    }).catch(err => {
        console.log(err);
        throw err;
    });
}

function startNgProgress() {
    if (typeof window !== "undefined") {
        NProgress.start();
    }
}

function prepareQS(params) {
    if (typeof params.text === "undefined") {
        params.text = "";
    }
    if (typeof params.order === "undefined") {
        params.order = "";
    }
    if (typeof params.subCategory1Id === "undefined") {
        params.subCategory1Id = 0;
    }
    if (typeof params.subCategory2Id === "undefined") {
        params.subCategory2Id = 0;
    } else {
        params.subCategory1Id = 0;
    }
    if (typeof params.section === "undefined") {
        params.section = 0;
    }
    if (typeof params.pageSize === "undefined") {
        params.pageSize = 40;
    }

    let minPriceQs = ''
    if (typeof params.minPrice !== "undefined" && parseInt(params.minPrice) !== 0) {
        minPriceQs = '&minPrice=' + params.minPrice
    }
    let maxPriceQs = ''
    if (typeof params.maxPrice !== "undefined") {
        maxPriceQs = '&maxPrice=' + params.maxPrice
    }

    let brandQs = ''
    if (typeof params.brand !== "undefined") {
        if (Array.isArray(params.brand)) {
            params.brand.forEach((brand) => {
                brandQs = brandQs + '&brandId=' + brand.id
            })
        } else {
            brandQs = '&brandId=' + params.brand.id
        }
    }

    let minRatingQs = ''
    if (typeof params.minRating !== "undefined" && parseInt(params.minRating) !== 0) {
        minRatingQs = '&minRating=' + params.minRating
    }
    let maxRatingQs = ''
    if (typeof params.maxRating !== "undefined") {
        maxRatingQs = '&maxRating=' + params.maxRating
    }

    let retailerQs = '&retailerId='
    if (typeof params.retailerId !== "undefined") {
        if (Array.isArray(params.retailerId)) {
            params.retailerId.forEach((retailer) => {
                retailerQs = retailerQs + retailer + '&retailerId='
            })
        } else {
            retailerQs = retailerQs + params.retailerId
        }
    }

    let tagQs = ''
    if (typeof params.tags !== "undefined") {
        if (Array.isArray(params.tags)) {
            params.tags.forEach((tag) => {
                tagQs = tagQs + '&tagId=' + tag.id
            })
        } else {
            tagQs = '&tagId=' + params.tags.id
        }
    }

    let minPriorityQs = ''
    if (typeof params.minPriority !== "undefined" && parseInt(params.minPriority) !== 0) {
        minPriorityQs = '&minPriority=' + params.minPriority
    }

    let mainCategoryIdQS = ''
    if (typeof params.mainCategoryId !== "undefined") {
        minPriorityQs = '&mainCategoryId=' + params.mainCategoryId
    }

    let slugQS = ''
    if (typeof params.slug !== "undefined") {
        slugQS = '&slug=' + params.slug
    }
    let collectionid = ''
    if (typeof params.collectionid !== "undefined") {
        collectionid = '&collectionid=' + params.collectionid
    }

    return '/product?text=' + params.text + slugQS + mainCategoryIdQS + collectionid + minPriceQs + maxPriceQs + minRatingQs + maxRatingQs + minPriorityQs + retailerQs + "&model=" + brandQs + tagQs + "&asin=&ean=&subCategory1Id=" + params.subCategory1Id + "&subCategory2Id=" + params.subCategory2Id + "&orderby=" + params.order + "&section=" + params.section + "&page=" + params.page + "&pageSize="+params.pageSize
}

async function getProductsByMutation(params, commit, mutation = "setProducts") {
    startNgProgress()
    return axios.get(baseURL + prepareQS(params)).then(response => {
        commit(mutation, response.data.products);
        commit('setCategories', response.data.metadata.categoriesTree)
        commit('saveSelectedCategory', response.data.metadata.selectedCategory);
        commit('setBreadcrumb', response.data.metadata.breadcrumb);
        if (params.server) commit("setProductsPrefetched", params.server);
        NProgress.done();
    }).catch(err => {
        NProgress.done();
        console.log(err);
    });
}

async function getProductsByMutationConstructor(params, commit, scroll = false) {
    return axios.get(baseURL + prepareQS(params)).then(response => {
        if (!scroll) commit("setProductsConstructor", response.data.products);
        else commit("setProductsConstructorScroll", response.data.products);
    }).catch(err => {
        console.log(err);
    });
}


export const getCollectionProducts = async ({ commit }, params) => {
    commit("setCollectionProducts", null);
    commit("setLoadingProducts",true)
    await getProductsByMutation(params,commit,"setCollectionProducts")
    commit("setLoadingProducts",false)
}

export const getProducts = async ({ commit }, params) => {
    commit("setProducts", []);
    commit("setLoadingProducts",true)
    await getProductsByMutation(params,commit,"setProducts")
    commit("setLoadingProducts",false)
    commit('setCanScroll')
}

export const getProductsConstructor = async ({ commit }, params) => {
    commit("setProductsConstructor", []);
    await getProductsByMutationConstructor(params,commit)
}

export const resetProducts = async ({ commit }) => {
    commit("setProducts", []);
}

export const resetProductsConstructor = async ({ commit }) => {
    commit("setProductsConstructor", []);
}

export const getProductsScroll = async ({ commit }, params) => {
    await getProductsByMutation(params,commit,"setProductsScroll")
}

export const getProductsConstructorScroll = async ({ commit }, params) => {
    await getProductsByMutationConstructor(params,commit,true)
}

export const getBrandsByRoute = ({ commit }, route) => {
    if (route.name === 'catalogue' || route.name === 'search') {
        return axios.get(baseURL + '/brand').then(response => {
            commit('setBrands', response.data)
        }).catch(err => {
            console.log(err)
        })
    }
    if (route.name === 'category') {
        // TODO Categoria o subcategories. Filtre de brand per slug!!!
        // let params = {}
        // if (route.query.subCategory) {
        //     params = {
        //         subCategory2Id: route.params.id
        //     }
        // } else {
        //     params = {
        //         subCategory1Id: route.params.id
        //     }
        // }
        // return axios.get(baseURL + '/brand', { params }).then(response => {
        //     commit('setBrands', response.data)
        // }).catch(err => {
        //     console.log(err)
        // })

        let params = {}
        params = {
            mainCategoryId: route.params.id
        }
        return axios.get(baseURL + '/brand', { params }).then(response => {
            commit('setBrands', response.data)
        }).catch(err => {
            console.log(err)
        })
    }

    commit('setBrands', [])
}

export const getTagsByRoute = ({ commit }, route) => {
    if (route.name === 'catalogue') {
        return axios.get(baseURL + '/tag/group/filter').then(response => {
            commit('setTags', response.data)
        }).catch(err => {
            console.log(err)
        })
    }
    if (route.name === 'subcategory') {
        let params = {}
        if (route.query.subCategory) {
            params = {
                subCategory2Id: route.params.id
            }
        } else {
            params = {
                subCategory1Id: route.params.id
            }
        }
        return axios.get(baseURL + '/tag/group/filter', { params }).then(response => {
            commit('setTags', response.data)
        }).catch(err => {
            console.log(err)
        })
    }
    commit('setTags', [])
}

export const getListConstructor = ({ commit }) => {
    return axios.get("https://list-builder-service.azurewebsites.net/api/GetListBuilder?code=BK_10S7sPZPAtVFg3nciPw5yGGFGfkJusS4S9BGwQcfpAzFuUALxJQ==").then(response => {
        commit("setListConstructor", response.data);
    }).catch(error => {
        console.log("error en getListConstructor". error);
    });
}

export const deleteUserAccount = async({ state }, user) => {
    await axios.delete(baseURL + "/User/" + user.id,axiosOptions(state));
}

// A nivell de backend, la crida és --> DELETE /api/User/{id}

// actions.js

export const iniciarProceso = async ({ commit, dispatch }, firstStepData) => {


    if (!firstStepData || Object.values(firstStepData).some(value => value === null || value === undefined)) {
        console.error('Invalid data received in iniciarProceso:', firstStepData);
        return;
    }

    commit('setDatosPrimerPaso', firstStepData);

    await dispatch('realizarSegundoPaso', firstStepData);
};

export const realizarSegundoPaso = async ({ commit, dispatch }, secondStepData) => {

    console.log(secondStepData);
    if (!secondStepData || Object.values(secondStepData).some(value => value === null || value === undefined)) {
        console.error('Invalid data received in setDatosSegundoPaso:', secondStepData);
        return;
    }

    commit('setDatosSegundoPaso', secondStepData);
};

export const saveListPwd = async ({ commit }, listPwd) => {
    commit("setListPwd", listPwd);
};

export const patchListPwd = async ({ commit, state }, listPwd) => {
    await axios.patch(baseURL + "/user/listpassword", JSON.stringify(listPwd), axiosOptions(state));
    commit("updateListField", { field: "password", value: listPwd })
}

export const createStripeAccount = async ({ commit, state }, stripeUserData) => {
    try {
        const apikey = process.env.STRIPE_HBB_API_KEY;
        let options =
          {
              headers: {
                  'Content-Type': 'application/json',
                  'x-functions-key': apikey
              }
          }
        const response = await axios.post(process.env.STRIPE_HBB_API_URL + "/account", stripeUserData, options);

        if (response.status === 200) {
            const stripeData = {
                StripeAccountId: response.data.id,
                AddressLine1: response.data.company.address.line1,
                Country: response.data.company.address.country,
                PostalCode: response.data.company.address.postal_code,
                City: response.data.company.address.city,
                Province: response.data.company.address.state
            };

            await updateSessionUserField(state, "stripeAccountId", stripeData.StripeAccountId);
            await updateSessionUserField(state, "addressLine1", stripeData.AddressLine1);
            await updateSessionUserField(state, "country", stripeData.Country);
            await updateSessionUserField(state, "postalCode", stripeData.PostalCode);
            await updateSessionUserField(state, "city", stripeData.City);
            await updateSessionUserField(state, "province", stripeData.Province);

            commit("setStripeData", stripeData);

            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error creating stripe account:", error);
        throw error;
    }
};

export const createCheckoutSession = async ({ commit, state }, data) => {
    try {
        const apikey = process.env.STRIPE_HBB_API_KEY;
        let options =
          {
              headers: {
                  'Content-Type': 'application/json',
                  'x-functions-key': apikey
              }
          }
        const response = await axios.post(process.env.STRIPE_HBB_API_URL + "/checkout", data, options);

        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error creating checkout session:", error);
        throw error;
    }
};

export const setVerifiedStatus = async ({ commit, state }, verifiedStatus) => {

    console.log(verifiedStatus);
    await updateSessionUserField(state, "verifiedStatus", verifiedStatus);

    commit('setVerifiedStatus', verifiedStatus);
};

export const getVerifiedStatus = async ({ commit, state }) => {
    const response = await axios.get(baseURL + '/user/verifiedstatus', axiosOptions(state));
    if (response.status === 200) {
        commit("setVerifiedStatus", response.data);
        await updateSessionUserField(state, "verifiedStatus", response.data);
        return response.data;
    }
    else {
        console.log("Error al obtener el estado de verificación:", response);
    }
}

export const setContributions = async ({ commit, state },  contributions) => {
    try {
        const data = { contributions: contributions };

        const jsonPayload = JSON.stringify(data);

        const response = await axios.put(baseURL + "/user/feature-flag", jsonPayload, axiosOptions(state));

        if (response.status === 200) {
            console.log(response.data)
            commit('setContributionsFlag', contributions);
            updateSessionUserField(state, "featureFlags", state.user.featureFlags);
            return response;
        } else {
            return response;
        }
    } catch (error) {
        console.error("Error al agregar las contribuciones:", error);
        throw error;
    }
};

export const getBlogAuthors = async ({ commit }, slug) => {
    try {
        const response = await ghostApi.authors.read({
            slug: slug
        });

        commit("setAuthorData", response);

    } catch (error) {
        console.log(error);
    }
}

export const getExternalAccount = async ({ state, commit }, { stripeAccountId, server }) => {
    const apikey = process.env.STRIPE_HBB_API_KEY;
    const options = axiosOptions(state);

    options.headers["x-functions-key"] = apikey;

    const url = process.env.STRIPE_HBB_API_URL + `/account/${stripeAccountId}/externalaccount`;

    const response = await axios.get(url, options);

    const externalAccount = response.data || null;
    commit("setExternalAccount", externalAccount);

    return externalAccount;
}

export const addIban = async ({ state }, { payload }) => {
    const apikey = process.env.STRIPE_HBB_API_KEY;
    let options =
      {
          headers: {
              'Content-Type': 'application/json',
              'x-functions-key': apikey
          }
      }
    const { userAccountId, ...ibanPayload } = payload;
    const url = process.env.STRIPE_HBB_API_URL + `/account/${userAccountId}/externalaccount`;
    return await axios.put(url, ibanPayload, options);
}

export const stripeAccountExistsByEmail = async ({ state }, { email }) => {
    const apikey = process.env.STRIPE_HBB_API_KEY;
    let options =
        {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': apikey
            }
        }
    const url = process.env.STRIPE_HBB_API_URL + `/account/${email}`;
    return await axios.get(url, options);
}

export const getStripeAccountByUserId = async ({ state, commit }, { id }) => {
    const apikey = process.env.STRIPE_HBB_API_KEY;
    let options =
        {
            headers: {
                'Content-Type': 'application/json',
                'x-functions-key': apikey
            }
        }
    const url = process.env.STRIPE_HBB_API_URL + `/getaccount/${id}`;
    var res = await axios.get(url, options);
    if (res.status === 200) {
        await updateSessionUserField(state, "stripeAccountId", res.data);
        commit("updateUserField", { field: "stripeAccountId", value: res.data })
        commit("updateListField", { field: "stripeAccountId", value: res.data })
    }
    return res;
}

export const deletePiggyBank = async({ state }, stripeAccountId) => {
    const options = axiosOptions(state);

    const apikey = process.env.STRIPE_HBB_API_KEY;
    options.headers['x-functions-key'] = apikey;

    const url = process.env.STRIPE_HBB_API_URL + `/account/${encodeURIComponent(stripeAccountId)}`;

    await axios.delete(url, options);
    await updateSessionUserField(state, "stripeAccountId", null);
}

export const getBalance = async({ state, commit }, stripeAccountId) => {
    const options = axiosOptions(state);

    const apikey = process.env.STRIPE_HBB_API_KEY;
    options.headers['x-functions-key'] = apikey;

    const url = process.env.STRIPE_HBB_API_URL + `/balance/${encodeURIComponent(stripeAccountId)}`;

    const response = await axios.get(url, options);
    const balance = response.data;

    commit("setBalance", balance);

    return balance;
}

export const postCheckoutFund = async ({ commit, state }, data) => {
    try {
        const body = {
            accountId: data.accountId,
            amount: data.amountCents,
            fundName: data.fundName,
            fundImage: data.fundImage,
            fundId: data.fundId,
            listId: data.listId,
            contributorName: data.contributorName,

            // TODO: Remove when validation is removed.
            email: "test@example.com",
        };

        // Simple sanity check.
        Object.keys(body).forEach((key) => {
            if (typeof body[key] === "undefined") {
                throw new Error(`Missing required key: ${requiredKey}`);
            }
        });

        const options = axiosOptions(state);
        options.headers["x-functions-key"] = process.env.STRIPE_HBB_API_KEY;

        const response = await axios.post(process.env.STRIPE_HBB_API_URL + "/checkout/fund", body, options);

        return response;
    } catch (error) {
        console.error("Error during request to `/checkout/fund`", error);

        throw error;
    }
};

export const getCurrencies = async ({ commit }, server = false) => {
    try {
        const response = await axios.get(baseURL + '/currency');
        const currencies = response.data;
        commit("setCurrencies", currencies);
        if (server) {
            commit("setCurrenciesPrefetched", server);
        }
    } catch (err) {
        console.error(err);
    }
}

export const updateUserCurrency = async ({ commit, state }, { currencyId }) => {
    try {
        const options = axiosOptions(state);
        const body = currencyId;
        const currency = state.currencies.find((c) => c.id === currencyId);
        if (!currency) {
            throw new Error(`Tried to update currency to an unknown ID: ${currencyId}`);
        }
        await axios.patch(baseURL + '/user/currency', body, options);
        const userFields = {
            currencyISOCode: currency.isoCode,
            currencyName: currency.name,
            currencySymbol: currency.symbol,
        };

        for (const entry of Object.entries(userFields)) {
            const [key, value] = entry;

            // TODO: Update all fields in a single request, instead of doing
            // one request per field.
            await updateSessionUserField(state, key, value);

            commit("updateUserField", {
                field: key,
                value: value,
            });
        }
    } catch (err) {
        console.error(err);
    }
}

// TODO: Remove this, and move all code into `upgradeToPremium` function.
export const postCheckoutUpgrade = async ({ commit, state }, { userId, email }) => {
    try {
        const liveModeEnabled = (process.env.STRIPE_CHECKOUT_LIVE_MODE === "1");

        const body = {
            userId,
            email,

            livemode: liveModeEnabled,
            callbackPath: "/mi-lista",
            callbackCancelledPath: "/mi-lista",
        };

        const options = axiosOptions(state);
        options.headers["x-functions-key"] = process.env.STRIPE_HBB_API_KEY;

        const response = await axios.post(process.env.STRIPE_HBB_API_URL + "/checkout/upgrade", body, options);

        return response;
    } catch (error) {
        console.error("Error during request to `/checkout/fund`", error);

        throw error;
    }
};

export const upgradeToPremium = async ({ commit, state }) => {
    const userId = state.user.id;
    const email = state.user.email;

    const response = await postCheckoutUpgrade({ commit, state }, {
        userId,
        email,
    });

    const redirectUrl = response.data.url;

    // TODO: Validate `redirectUrl`.

    window.location.href = redirectUrl;
};

export const getCheckoutSessionId = async ({ commit, state }, { checkoutSessionId }) => {
    if (typeof checkoutSessionId !== "string") {
        throw new Error("`checkoutSessionId` is required.");
    }

    try {
        const options = axiosOptions(state);
        options.headers["x-functions-key"] = process.env.STRIPE_HBB_API_KEY;

        const path = `/checkout/${encodeURIComponent(checkoutSessionId)}`

        const response = await axios.get(
            process.env.STRIPE_HBB_API_URL + path,
            options
        );

        const responseBody = response.data;

        return responseBody;
    } catch (error) {
        console.error("Error during request to `GET /checkout/{session_id}`", error);

        throw error;
    }
};

export const getGuestCountry = async ({ commit, state }) => {
    try {
        console.log("Fetching guest country.");

        const options = axiosOptions(state);

        const response = await axios.get("/api/country", options);
        console.log("response getGuestCountry", response)
        const responseBody = response.data;
        const countryCode = responseBody.countryIsoCode;

        commit("setGuestCountry", countryCode);

        return countryCode;
    } catch (error) {
        const fallbackCountryCode = process.env.FALLBACK_GUEST_COUNTRY;
        if (fallbackCountryCode) {
            console.warn(`Could not get country code from IP address. Using "${fallbackCountryCode}" as fallback.`);
            commit("setGuestCountry", fallbackCountryCode);
            return fallbackCountryCode;
        } else {
            throw error;
        }
    }
};

// Ideally this would be in the state.
//
// But using `commit` inside the `prefetchCountry` action doesn't seem to
// update the state soon enough, and it causes the request to be done multiple
// times.
//
// If it's possible to have this in state, we should do so.
let guestCountryPromise = null;

export const prefetchCountry = async (options) => {
    const { state } = options;

    if (!state.user || !state.user.locationCountry) {
        if (state.guestCountry && state.guestCountry !== "") {
            console.log("Guest country was already present in state. No need to prefetch.");
            return state.guestCountry;
        }

        try {
            console.log((new Error()).stack);
            const p = await getGuestCountry(options);
            guestCountryPromise = p;
            console.log("Prefetched guest country:", p);
            return p;
        } catch (err) {
            console.error("Could not prefetch guest country: ", err);
        } finally {
            guestCountryPromise = null;
        }
    }
};

export const updateContributionConfiguration = async ({ state, commit }, payload) => {
    const url = `${baseURL}/user/contributionconfiguration`;

    await axios.put(
        url,
        payload,
        axiosOptions(state)
    );

    await updateSessionUserField(state, "contributionsConfiguration", payload)
    commit("setContributionsConfiguration", payload);
}

export const getPopupCookie = async ({ state, commit }, payload) => {
    const response = await axios.get("/popup");

    return response.data.ok;
}

export const setPopupCookie = async ({ state, commit }, payload) => {
    const response = await axios.post("/popup");

    return response.data.ok;
}

export const setListTemplate = async ({ commit, state }, payload) => {
    const url = baseURL + "/list/template";

    try {
        const body = {};

        return await axios.post(
            url,
            JSON.stringify(body),
            axiosOptions(state)
        );
    } catch (error) {
        console.error(`Error during request to ${url}`, error);

        throw error;
    }
};

export const getEcommerceCustomizations = async ({ commit, state }, clientId) => {
    try {
        return await getClientIdCustomizations(clientId);
    } catch (error) {
        console.error(`Error during request to ${url}`, error);

        throw error;
    }
};

export const updateBabyRegistryFlag = async({ state }, value) => {
    await axios.patch(
        baseURL + "/user/babyregistryflag",
        JSON.stringify(value),
        axiosOptions(state),
    );
}

export const sellerRequestInfo = async ({ commit, state }, payload) => {
    const url = `${process.env.EMAIL_SENDER_API_URL}/HttpTriggerInfoEmail`
    const apiKey = process.env.EMAIL_SENDER_API_KEY;

    try {
        const body = {
            email: payload.email,
            company: payload.company,
            message: payload.message,
        };

        return await axios.post(
            url,
            JSON.stringify(body),
            {
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": apiKey,
                },
            },
        );
    } catch (error) {
        console.error(`Error during request to ${url}`, error);

        throw error;
    }
};

export const setLanguage = async ({ commit, state }, payload) => {
    const url = baseURL + "/list/template";

    try {
        const { language } = payload;

        const resp = await axios.put(
            "/language",
            JSON.stringify({
                language,
            }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        commit("setLanguageWorkaround", language);

        return resp;
    } catch (error) {
        console.error(`Error during request to ${url}`, error);

        throw error;
    }
};

export const clearSiteData = async ({ commit, state }) => {
    try {
        await axios.post(
            "/clear-site-data",
            JSON.stringify({}),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
    } catch (error) {
        console.error("Could not clear site data.", error);

        throw error;
    }
};

export const getPromotions = async ({ commit, state }, listType) => {
    if (!listType) {
        throw new Error("listType is required");
    }

    try {
        const resp = await axios.get(
            `${baseURL}/promotion/${encodeURIComponent(listType)}`,
            axiosOptions(state)
        );

        const promotions = resp.data;

        commit("setPromotions", promotions);
        commit("setNextPromotionIndex", 0);

        return promotions;
    } catch (error) {
        console.error(`Error during request to ${url}`, error);

        throw error;
    }
};

export const getNextPromotionIndex = ({ commit, state }) => {
    const i = state.nextPromotionIndex;
    if (Array.isArray(state.promotions) && i < state.promotions.length) {
        commit("setNextPromotionIndex", i + 1);

        return i;
    }

    commit("setNextPromotionIndex", -1);

    return -1;
};

export const updateUserDefaultListId = async (options, listId) => {
    const { commit, state } = options;
    const result = await axios.patch(
        baseURL + "/user/defaultlist",
        {
            defaultlistId: listId,
        },
        axiosOptions(state)
    );

    if (result?.data) {
        commit("updateUserField", {
            field: "listId",
            value: listId,
        });

        await updateSessionUserField(state, "listId", listId)

        await getOwnerList(options);
    }
};

export const setListType = async ({ state }, listType) => {
    return await axios.patch(
        baseURL + "/list/type",
        JSON.stringify({
            type: listType,
        }),
        axiosOptions(state)
    );
};

export const createList = async ({ state }, payload) => {
    return await axios.post(
        baseURL + "/list",
        JSON.stringify(payload),
        axiosOptions(state)
    );
};

export const deleteList = async ({ state }, listId) => {
    return await axios.delete(
        `${baseURL}/list/${encodeURIComponent(listId)}`,
        axiosOptions(state)
    );
};

export const updateUserLists = async ({ state, commit }) => {
    const resp = await axios.get(
        baseURL + "/user/lists",
        axiosOptions(state)
    );

    const lists = resp.data;

    commit("updateUserField", {
        field: "lists",
        value: lists,
    });

    await updateSessionUserField(state, "lists", lists);

    return lists;
};

export const getProductStatusFree = async ({ state, commit }, productId) => {
    const resp = await axios.get(
        `${baseURL}/list/product/${encodeURIComponent(productId)}/status`,
        axiosOptions(state)
    );
    if (resp.status === 404) {
        return -1;
    }

    return resp.data;
};

export const getProductStatusCatalog = async ({ state, commit }, productId) => {
    const resp = await axios.get(
        `${baseURL}/list/listproduct/${encodeURIComponent(productId)}/status`,
        axiosOptions(state)
    );
    if (resp.status === 404) {
        return -1;
    }

    return resp.data;
};
